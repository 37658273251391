import { useRef } from 'react';
import { Color, MeshBasicMaterial} from 'three';
import { Canvas, useThree, useFrame} from 'react-three-fiber';
import { OrbitControls, useGLTF, shaderMaterial, Text3D, useMatcapTexture, Plane} from '@react-three/drei';
import anthemsFromTheAbyssCover from './media/artwork/anthems_from_the_abyss.jpg';
import RomicMedium from './RomicMedium.json';

const RainbowMaterial = shaderMaterial(
    { time: 0, color: new Color(0x00ff00) },
    // vertex shader
    `varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,
    // fragment shader
    `uniform float time;
    varying vec2 vUv;
    void main() {
        float r = 0.5 * cos(2.0 * 3.1416 * (vUv.x + time / 3.0)) + 0.5;
        float g = 0.5 * cos(2.0 * 3.1416 * (vUv.y + time / 3.0)) + 0.5;
        float b = 0.5 * cos(2.0 * 3.1416 * (vUv.x + vUv.y + time / 3.0)) + 0.5;
        vec4 rainbowColor = vec4(r, g, b, 1.0);
        vec4 whiteColor = vec4(1.0, 1.0, 1.0, 1.0);
        float saturation = 0.4; // Adjust this value to change the saturation
        gl_FragColor = mix(whiteColor, rainbowColor, saturation);
    }`
);

function Model() {
    const {clock} = useThree();
    const { nodes, materials } = useGLTF('/wireframe_3d_globe.glb');
    nodes.Object_2.rotation.x = Math.PI / 2;
    nodes.Object_2.rotation.y = 13 * (Math.PI / 180);
    const material = useRef(new RainbowMaterial());

    useFrame(() => {
        nodes.Object_2.rotation.z += 0.004; // Adjust this value to change the speed of rotation
        material.current.uniforms.time.value = clock.getElapsedTime();
    });

    return (
        <primitive object={nodes.Object_2} material={material.current} />
    );
}

function lerp(start, end, t) {
    return start * (1 - t) + end * t;
}

function RareEarthTonesText() {
    const {viewport} = useThree();
    const ref = useRef();
    const [matcapTexture] = useMatcapTexture('7877EE_D87FC5_75D9C7_1C78C0');

    // Text position logic
    const startWidth = 300; // Adjust as needed
    const endWidth = 250; // Adjust as needed
    
    // Calculate the interpolation factor
    let t = (viewport.width - startWidth) / (endWidth - startWidth);
    t = Math.max(0, Math.min(1, t)); // Clamp t to the range [0, 1]
    
    // Interpolate the text position
    const textPositionX = lerp(-45, -23, t);
    
    return (
        <Text3D 
            font={RomicMedium}                
            position={[textPositionX, 6, 53]}
            size={10}
            curveSegments={24}
            brevelSegments={1}
            bevelEnabled
            bevelSize={0.1}
            bevelThickness={0.1}
            height={1}
            lineHeight={0.65}
            letterSpacing={0.3}    
        >
            {`RARE\nEARTH\nTONES`}
            <meshMatcapMaterial color="white" matcap={matcapTexture} ref={ref} />
        </Text3D>
    );
}

export default function Home() {
    return (
        <>
            <div className="canvas">
                <Canvas camera={{ position: [0, 0, 100], fov: 100 }}>
                    <Model />
                    <Plane args={[1000, 1000]} position={[-45, 6, 51]} rotation={[0, 0, 0]}>
                        <meshBasicMaterial color="black" transparent opacity={0.5} />
                    </Plane>
                    <RareEarthTonesText />
                </Canvas>            
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', paddingBottom: 120}}>
                <a 
                    href="https://dubbeldutch.bandcamp.com/album/anthems-from-the-abyss"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                <div
                    className="large-cover"
                    style={{backgroundImage: `url(${anthemsFromTheAbyssCover})`}}
                />
                </a>
                <a 
                    href="https://dubbeldutch.bandcamp.com/album/anthems-from-the-abyss"
                    style={{fontSize: 20, fontWeight: 'bold', padding: 10}} 
                    target="_blank"
                    rel="noopener noreferrer"
                >Anthems From The Abyss EP Out Now!</a>
            </div>
            <iframe
                style={{
                    height: 'calc(100vw)',
                    width: '100%',
                    border: 'none',
                }}
                src="https://www.youtube.com/embed/hcXf6RKR5NE"
                title="Passiflora"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
            <iframe
                style={{
                    height: 'calc(100vw)',
                    width: '100%',
                    border: 'none',
                }}
                src="https://www.youtube.com/embed/0QaBiYVJOWQ"
                title="Passiflora"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />

        </>
    );
}
