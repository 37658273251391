import React, { useState } from 'react';
import BandCampIcon from './BandcampIcon.tsx';
import YoutubeIcon from './YoutubeIcon.tsx';
import InstagramIcon from './InstagramIcon.tsx';
import SoundcloudIcon from './SoundCloudIcon.tsx';
import TwitterIcon from './TwitterIcon.tsx';

export default function Contact() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('email', email);
        formData.append('message', message);
        setLoading(true);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'contact', {
                method: 'POST',
                body: formData,
            });

            setLoading(false);
    
            if (response.ok) {
                setEmail('');
                setMessage('');
                setShowSuccessMessage(true);
                setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 5000);
            } else {
                setShowFailureMessage(true);
                setTimeout(() => {
                    setShowFailureMessage(false);
                }, 5000);
            }    
        } catch (error) {
            setShowFailureMessage(true);
            setTimeout(() => {
                setShowFailureMessage(false);
            }, 5000);
            setLoading(false);
        }
    }

    return (
        <>            
            <a className="contact-header email-list" href="https://forms.gle/s6vi8PF1MRuZa7m26">JOIN THE EMAIL LIST</a>
            <div className="contact-form">
            <p className="contact-header">FOLLOW ↓</p>
                <div className="socials">
                    <a href="https://dubbeldutch.bandcamp.com"><BandCampIcon /></a>
                    <a href="https://www.youtube.com/@dubbeldutchmusic/playlists"><YoutubeIcon /></a>
                    <a href="https://www.instagram.com/dutchdubs"><InstagramIcon /></a>
                    <a href="https://x.com/dubbeldutch"><TwitterIcon /></a>
                    <a href="https://soundcloud.com/dubbeldutch"><SoundcloudIcon /></a>
                </div>
                <br />
                <p className="contact-header">MESSAGE ↓</p>
                <form onSubmit={handleSubmit}>
                    <label>
                        email:
                        <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                    </label>
                    <label>
                        message:
                        <textarea value={message} onChange={e => setMessage(e.target.value)} required />
                    </label>
                    <input type="submit" value={loading ? "loading..." : "submit"} />
                    {showSuccessMessage && <div className="message-sent">Message sent!</div>}
                    {showFailureMessage && <div className="message-failed">Failed to send</div>}
                </form>
            </div>
        </>
    );
}